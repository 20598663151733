import { ASTKinds, type Expression, type Modifier, parse } from './dice'
import { type Die, roll } from './diceRoll'

type DiceExpression = Expression

export type DiceExpr = string | number

export type ExtraMod = {
  type: string
  arg?: number
}

export type DiceContext = {
  variables: Record<string, number>
  globalMods: Modifier[]
  extraMods: ExtraMod[]
}

export type NumberPart = { type: 'num'; num: number }
export type OperatorPart = { type: 'op'; op: string }
export type VariablePart = { type: 'var'; value: number; name: string }
export type DicePart = { type: 'die'; die: Die }
export type ResultPart = OperatorPart | DicePart | VariablePart | NumberPart
export type SingleDiceResult = {
  rolled: number
  average: number
  result: ResultPart[]
  resultSimple: ResultPart[]
  constant: boolean
  expression: string
  simplified: string
}

export type MultiDiceResult = {
  results: SingleDiceResult[]
  player: string
  id: number
  hidden?: boolean
  label: string | null
}

export function isCheck(res: SingleDiceResult): boolean {
  // a single d20 is part of the result => is a check
  const dice = res.result.filter((d) => d.type === 'die' && d.die.sides === 20 && !d.die.tags.includes('ignored'))
  return dice.length === 1
}

export function isCriticalCheck(res: SingleDiceResult): boolean {
  // a single d20 is part of the result and it rolled maximum => is a crit
  const dice = res.result.filter(
    (d) => d.type === 'die' && d.die.sides === 20 && d.die.tags.includes('maximum') && !d.die.tags.includes('ignored')
  )
  return dice.length === 1
}

export function resolveDice(expr: DiceExpr, variables: Record<string, number> = {}, constant: boolean = false) {
  const ctx = { variables, globalMods: [], extraMods: [] }
  const diceExpr = parseDice(expr, ctx)
  if (!diceExpr) return 0
  const result = evaluate(diceExpr, ctx)
  return constant ? result.average : result.rolled
}

export function parseDice(expr: DiceExpr, ctx: DiceContext): DiceExpression | null {
  expr = `${expr}` // force string
  const input = expr.replaceAll(/\s/g, '')
  const result = parse(input)
  if (result.errs.length) console.error(`dice parsing error: '${expr}'`, result.errs)
  ctx.globalMods = result.ast?.globalMods?.modifiers ?? []
  return result.ast?.expression ?? null
}

function binary(
  lhs: SingleDiceResult,
  rhs: SingleDiceResult,
  fn: (a: number, b: number) => number,
  op: string,
  opNice: string = op
): SingleDiceResult {
  const rolled = fn(lhs.rolled, rhs.rolled)
  const average = fn(lhs.average, rhs.average)
  const constant = lhs.constant && rhs.constant
  const result: ResultPart[] = [...lhs.result, { type: 'op', op }, ...rhs.result]
  const resultSimple: ResultPart[] = constant
    ? ([{ type: 'num', num: average }] as NumberPart[])
    : [...lhs.resultSimple, { type: 'op', op } as OperatorPart, ...rhs.resultSimple]
  const expression = `${lhs.expression}${opNice}${rhs.expression}`
  const simplified = constant ? `${average}` : `${lhs.simplified}${opNice}${rhs.simplified}`
  const res: SingleDiceResult = {
    rolled,
    average,
    result,
    resultSimple,
    constant,
    expression,
    simplified,
  }
  return res
}

export function evaluate(expr: DiceExpression, ctx: DiceContext): SingleDiceResult {
  const e = (x: DiceExpression) => evaluate(x, ctx)
  const str = (x: any) => `${x}`
  if (!expr)
    return {
      rolled: 0,
      average: 0,
      result: [{ type: 'var', value: 0, name: 'error' }],
      resultSimple: [{ type: 'var', value: 0, name: 'error' }],
      constant: true,
      expression: `(error)`,
      simplified: `(error)`,
    }
  switch (expr.kind) {
    case ASTKinds.Dice: {
      const amount = expr.amount ? e(expr.amount).rolled : 1
      const sides = e(expr.sides).rolled

      return roll(amount, sides, expr.modifiers, ctx)
    }
    case ASTKinds.Constant: {
      const value = parseInt(expr.value)
      return {
        rolled: value,
        average: value,
        result: [{ type: 'num', num: value }],
        resultSimple: [{ type: 'num', num: value }],
        constant: true,
        expression: str(value),
        simplified: str(value),
      }
    }
    case ASTKinds.Variable: {
      const { name } = expr
      const value = ctx.variables[name] || 0
      return {
        rolled: value,
        average: value,
        result: [{ type: 'var', value, name }],
        resultSimple: [{ type: 'var', value, name }],
        constant: true,
        expression: str(value),
        simplified: str(value),
      }
    }
    case ASTKinds.Add: {
      const lhs = e(expr.lhs)
      const rhs = e(expr.rhs)
      return binary(lhs, rhs, (a, b) => a + b, '+')
    }
    case ASTKinds.Multiply: {
      const lhs = e(expr.lhs)
      const rhs = e(expr.rhs)
      return binary(lhs, rhs, (a, b) => a * b, '*', '×')
    }
    case ASTKinds.Divide: {
      const lhs = e(expr.lhs)
      const rhs = e(expr.rhs)
      return binary(lhs, rhs, (a, b) => Math.floor(a / b), '/')
    }
    case ASTKinds.Subtract: {
      const lhs = e(expr.lhs)
      const rhs = e(expr.rhs)
      return binary(lhs, rhs, (a, b) => a - b, '-')
    }
  }
}
